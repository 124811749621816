<template>
  <div>
  <nav class="site-navbar" :class="'site-navbar--' + navbarLayoutType">
    <div class="site-navbar__header">
      <h1 class="site-navbar__brand" @click="$router.push({ name: 'home' })">
        <a class="site-navbar__brand-lg" href="javascript:;">
<!--			<img src="@/assets/images/logo02.png"  style="height: 30px;">-->
			监理管理
		</a>
        <a class="site-navbar__brand-mini" href="javascript:;">
<!--			<img src="@/assets/images/logoMini02.png"  style="height: 30px;"/>-->
			监理
		</a>
      </h1>
    </div>
    <div class="site-navbar__body clearfix" style="height: 50px;" v-if="menuList">
		<el-row>
        <el-col :span="1">
          <el-menu class="site-navbar__menu" mode="horizontal" :default-active="menuIndex + ''">
            <el-menu-item class="ignore-active" :index="0 + ''" @click="sidebarFold = !sidebarFold">
              <i class="el-icon-s-fold" v-if="!sidebarFold" style="margin-top: -3px;color: #FFFFFF;"></i>
              <i class="el-icon-s-unfold" v-if="sidebarFold" style="margin-top: -3px;color: #FFFFFF;"></i>
            </el-menu-item>
          </el-menu>
        </el-col>
			<el-col :span="17">
      <el-menu class="site-navbar__menu"
		mode="horizontal"
		:default-active="menuIndex + ''"
		style="margin-left: 10px;">
		<el-menu-item :index="index+''" @click="setSelNavbarIndex(index)" v-for="(item,index) in menuList" v-if="item.list.length>=1">
			{{item.name}}
		</el-menu-item>
      </el-menu>
	</el-col>
	<el-col :span="6">
		  <el-menu class="site-navbar__menu"
			mode="horizontal"
			:default-active="menuIndex + ''">
			<el-menu-item class="ignore-active" :index="index + ''" @click="setProjectKind(index)" v-for="(item, index) in menuList"
					v-if="item.list.length == 0 && item.name === '项目设置'">
				<i class="el-icon-s-tools" style="margin-top: -3px;color: #FFFFFF;" ></i> {{ item.name }}
			</el-menu-item>
	      </el-menu>
		  <el-menu class="site-navbar__menu"
			mode="horizontal"
			:default-active="menuIndex + ''"
			style="margin-left: 0px;">
			<el-menu-item :index="index + ''" @click="setSelProjectIndex(index)" v-for="(item, index) in menuList"
					v-if="item.list.length == 0 && item.name === '项目列表'">
        <svg style="margin-top: -3px;width: 24px;height: 18px;" t="1703579035010" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4508" width="200" height="200"><path d="M187.392 70.656q28.672 0 48.64 19.456t19.968 48.128l0 52.224q0 28.672-19.968 48.64t-48.64 19.968l-54.272 0q-27.648 0-47.616-19.968t-19.968-48.64l0-52.224q0-28.672 19.968-48.128t47.616-19.456l54.272 0zM889.856 70.656q27.648 0 47.616 19.456t19.968 48.128l0 52.224q0 28.672-19.968 48.64t-47.616 19.968l-437.248 0q-28.672 0-48.64-19.968t-19.968-48.64l0-52.224q0-28.672 19.968-48.128t48.64-19.456l437.248 0zM187.392 389.12q28.672 0 48.64 19.968t19.968 48.64l0 52.224q0 27.648-19.968 47.616t-48.64 19.968l-54.272 0q-27.648 0-47.616-19.968t-19.968-47.616l0-52.224q0-28.672 19.968-48.64t47.616-19.968l54.272 0zM889.856 389.12q27.648 0 47.616 19.968t19.968 48.64l0 52.224q0 27.648-19.968 47.616t-47.616 19.968l-437.248 0q-28.672 0-48.64-19.968t-19.968-47.616l0-52.224q0-28.672 19.968-48.64t48.64-19.968l437.248 0zM187.392 708.608q28.672 0 48.64 19.968t19.968 47.616l0 52.224q0 28.672-19.968 48.64t-48.64 19.968l-54.272 0q-27.648 0-47.616-19.968t-19.968-48.64l0-52.224q0-27.648 19.968-47.616t47.616-19.968l54.272 0zM889.856 708.608q27.648 0 47.616 19.968t19.968 47.616l0 52.224q0 28.672-19.968 48.64t-47.616 19.968l-437.248 0q-28.672 0-48.64-19.968t-19.968-48.64l0-52.224q0-27.648 19.968-47.616t48.64-19.968l437.248 0z" p-id="4509" fill="#FFFFFF"></path></svg>
        {{ item.name }}
			</el-menu-item>
	      </el-menu>
		</el-col>
	</el-row>
<!--	  <el-menu class="site-navbar__menu site-navbar__menu&#45;&#45;right" mode="horizontal">-->
<!--	  		<el-menu-item class="back-list">-->
<!--	  			<img src="@/assets/images/backIcon.png" alt="">-->
<!--	  			返回-->
<!--	  		</el-menu-item>-->
<!--	  </el-menu>-->
      <el-menu class="site-navbar__menu site-navbar__menu--right" mode="horizontal" v-if="false">
		<el-menu-item class="back-list">
			标的物
			<el-select class="topMenuSel" v-model="selectSubjectNo" placeholder="请选择" @change="setSubjectNo()">
				<el-option
				  v-for="item in $store.state.common.navBarSubjectList"
				  :key="item.displayValue"
				  :label="item.displayName"
				  :value="item.displayValue">
				</el-option>
			</el-select>
		</el-menu-item>
      </el-menu>
    </div>
  </nav>
  <project-kind-setting v-if="projectKindSettingVisible" ref="projectKindSetting"></project-kind-setting>
  </div>
</template>

<script>
  import $common from '@/utils/common.js'
  import ProjectKindSetting from './modules/projectInfo/project-kind-setting.vue'
  export default {
    data() {
      return {
        updatePassowrdVisible: false,
		projectKindSettingVisible: false,
		searchKey: "",
		menuList: [],
		subjectList:[],
		subjectNo:"",
      }
    },
	props:{
		menuIndex:{
			type: Number,
			required: true
		}
	},
	watch: {
      $route: 'routeHandle'
    },
	created () {
      this.routeHandle(this.$route)
    },
	  activated() {

	  },
    mounted() {
      this.menuList = $common.getItem("menuList")
      // this.subjectList = this.$store.state.common.navBarSubjectList
		// if($common.getItem("subjectNo") == "" || $common.getItem("subjectNo") == undefined){
		// 	this.subjectNo = $common.getItem("subjectNo");
		// }
      // if (!this.subjectList){
	  // 	this.getSubjectDropDown()
	  // }
    },
    components: {
		ProjectKindSetting
    },
    computed: {
      navbarLayoutType: {
        get() {
          return this.$store.state.common.navbarLayoutType
        }
      },
		selectSubjectNo: {
			get () { return this.$store.state.common.selectSubjectNo },
			set (val) { this.$store.commit('common/updateSelectSubjectNo', val) }
		},
		updateSubjectList: {
			get () { return this.$store.state.common.navBarSubjectList },
			set (val) { this.$store.commit('common/updateSubjectList', val) }
		},
		sidebarFold: {
			get() { return this.$store.state.common.sidebarFold },
			set(val) { this.$store.commit('common/updateSidebarFold', val) }
		},
    },
    methods: {
	  setSelNavbarIndex: function(index){
		this.$emit("setSelNavbarIndex",index);
		let _menuList = this.menuList;
		if(_menuList[index].list && _menuList[index].list.length > 0){
			let _routerName = _menuList[index].list[0].routerName
			if(_routerName && _routerName != ''){
				if (_routerName != '/subjectList' && this.$store.state.common.navBarSubjectList.length >0){
					this.$router.push(_routerName);
				}
        // else {
				// 	this.$router.push("/subjectList");
				// 	this.$emit("setSelNavbarIndex",'0');
				// 	this.$emit("setSubMenuIndex",this.getSubjectMenuIndex());
				// }
				// this.$router.push({name: _routerName});
			}
		}
	  },
	  setSelProjectIndex: function(index){
		this.$emit("setSelProjectIndex",index);
		let _menuList = this.menuList;
		if(_menuList[index].list && _menuList[index].list.length == 0){
			let _url = _menuList[index].url
			if(_url && _url != ''){
				// this.$router.push({name: _routerName});
				if(_menuList[index].name === '项目列表') {
					this.$emit("setSelProjectIndex",'0');
					this.$router.push({ name: 'projectlist' });
				}
			}
		}
	  },
	  setProjectKind: function(index){
		this.projectKindSettingVisible = true;
		let _menuList = this.menuList;
		if(_menuList[index].list && _menuList[index].list.length == 0){
			let _url = _menuList[index].url
			if(_url && _url != ''){
				// this.$router.push({name: _routerName});
				if(_menuList[index].name === '项目设置') {
					// this.$router.push({ name: 'projectKindSetting' });
					this.$nextTick(() => {
						this.$refs.projectKindSetting.init()
					})
				}
			}
		}
	  },
		getSubjectMenuIndex(){
			let rtn = 0
			this.menuList[0].list.forEach(function(item,idx){
				if (item.routerName === '/subjectList'){
					rtn = idx
				}
			})
			return rtn
		},
		routeHandle (route) {
			let sessionFlg = $common.getItem('sessionFlg2');
			if (sessionFlg != null && sessionFlg != undefined && sessionFlg) {
				this.$emit("setMenuIndex", $common.getItem('menuIndex'));
				sessionStorage.setItem('sessionFlg2', false)
			} else {
			}
			// sessionStorage.setItem('subMenuIndex', $common.getItem('subMenuIndex'))
			// this.$emit("setSubMenuIndex",$common.getItem('subMenuIndex'));
			// console.log("subMenuIndex1111", $common.getItem('subMenuIndex'))
		},
	  getSubjectDropDown(){
	  	this.$http({
	  	  url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
	  	  method: "get",
	  	  params: {},
	  	}).then(({ data }) => {
	  	  if (data && data.resultCode === 200) {
	  		this.subjectList = data.body;
			  this.$store.commit('common/updateSubjectList', this.subjectList)
			  if (!this.subjectList){
				  this.$emit("setSubMenuIndex",this.getSubjectMenuIndex());
			  } else {
				  if($common.getItem("subjectNo") == "" || $common.getItem("subjectNo") == undefined){
				  // if(this.selectSubjectNo == "" || this.selectSubjectNo == undefined){
					  this.subjectNo = this.subjectList[0].displayValue;
					  $common.setItem("subjectNo", this.subjectList[0].displayValue);
					  this.$store.commit('common/updateSelectSubjectNo', this.subjectNo)
				  }else{
					  let isExist = false;
					  this.subjectList.forEach(function (item,idx) {
						  if (item.displayValue === this.selectSubjectNo){
							  isExist = true;
						  }
					  });
					  if (!isExist){
						  this.subjectNo = this.subjectList[0].displayValue;
						  $common.setItem("subjectNo", this.subjectList[0].displayValue);
						  this.$store.commit('common/updateSelectSubjectNo', this.subjectNo)
					  } else {
						  this.$store.commit('common/updateSelectSubjectNo', this.subjectNo)
						  // this.selectSubjectNo = $common.getItem("subjectNo");
					  }
				  }
			  }
	  	  }
	  	});
	  },
	  setSubjectNo(){
		$common.setItem("subjectNo", this.selectSubjectNo);
		this.$router.go(0)
	  }
    }
  }
</script>
